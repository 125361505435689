var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      class: _setup.mandantId == 1 ? "efm-bg" : "verdas-bg",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [_c("span", { staticClass: "navbar-title" }, [_vm._v("Risikoprofil")])]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "navbar-toggler d-lg-none",
          attrs: {
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
            "data-toggle": "collapse",
            href: "javascript:void(0);",
            right: "",
            target: "header-tabs",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: "header-tabs", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _setup.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: { name: "Dashboard" }, exact: "" } },
                    [_c("span", [_c("span", [_vm._v("Home")])])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _setup.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("span", { staticClass: "no-bold" }, [
                            _vm._v("Berichte"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: { name: "Reports" }, exact: "" } },
                        [_vm._v(" Alle")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: { name: "ReportCustomerSelection" },
                            exact: "",
                          },
                        },
                        [_vm._v(" Neu")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _setup.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("span", { staticClass: "no-bold" }, [
                            _vm._v("Einstellungen"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(_setup.SettingsMenu),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _setup.isAdmin()
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "pointer",
                      attrs: {
                        to: { name: "AppUsers" },
                        "active-class": "active",
                        exact: "",
                      },
                    },
                    [_c("span", [_c("span", [_vm._v("User")])])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _setup.isAdmin()
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _setup.subIsActive("/admin"),
                      },
                      attrs: {
                        id: "admin-menu",
                        "active-class": "active",
                        "data-cy": "adminMenu",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("span", { staticClass: "no-bold" }, [
                            _vm._v("Administration"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _setup.isAdmin()
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "pointer",
                              attrs: {
                                "active-class": "active",
                                exact: "",
                                to: "/admin/user-management",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Internal User")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/metrics",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "tachometer-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Metrics")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/health",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "heart" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Health")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "active-class": "active",
                            to: "/admin/logs",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Logs")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _setup.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _setup.subIsActive("/account"),
                      },
                      attrs: {
                        id: "account-menu",
                        "active-class": "active",
                        "data-cy": "accountMenu",
                        href: "javascript:void(0);",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("span", { staticClass: "no-bold" }, [
                            _vm._v(" Account "),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: { name: "SetupPage" },
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Setup")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _setup.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                id: "logout",
                                "active-class": "active",
                                "data-cy": "logout",
                              },
                              on: {
                                click: function ($event) {
                                  return _setup.logout()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "sign-out-alt" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Sign out")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }