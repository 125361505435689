import http from '@/shared/config/httpService';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IPercentage } from '@/shared/model/percentage.model';

const baseApiUrl = 'api/percentages';
const adminBaseApiUrl = 'api/admin/percentages';
export default class PercentageService {
  /**
   * Saves multiple entities.
   * @param entities An array of entities to save.
   * @returns A promise that resolves with the saved entities.
   */
  public async saveAll(entities: IPercentage[]): Promise<IPercentage[]> {
    const res = await http.post(`${adminBaseApiUrl}/all`, entities);
    return res.data;
  }

  /**
   * Retrieves an entity by ID.
   * @param id The ID of the entity to retrieve.
   * @returns A promise that resolves with the retrieved entity.
   */
  public async find(id: number): Promise<IPercentage> {
    const res = await http.get(`${baseApiUrl}/${id}`);
    return res.data;
  }

  /**
   * Retrieves data with optional pagination query.
   * @param paginationQuery The pagination query options.
   * @returns A promise that resolves with the retrieved data.
   */
  public async retrieve(paginationQuery?: any): Promise<any> {
    return http.get(`${baseApiUrl}?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  /**
   * Deletes an item by ID.
   * @param id The ID of the item to delete.
   * @returns A promise that resolves with the delete response.
   */
  public async delete(id: number): Promise<any> {
    return http.delete(`${adminBaseApiUrl}/${id}`);
  }
}
