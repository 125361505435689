import http from '@/shared/config/httpService';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IInsurance } from '@/shared/model/insurance.model';

const baseApiUrl = 'api/insurances';
const adminBaseApiUrl = 'api/admin/insurances';

export default class InsuranceService {
  public async saveAll(insurances: IInsurance[]): Promise<any> {
    const res = await http.post(`${adminBaseApiUrl}/all`, insurances);
    return res.data;
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return http.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public delete(id: number): Promise<any> {
    return http.delete(`${adminBaseApiUrl}/${id}`);
  }
}
